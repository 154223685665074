var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"home"},[_vm._m(1),_c('div',{staticClass:"products"},[_c('el-carousel',{staticStyle:{"max-width":"1440px","margin":"0 auto","box-shadow":"rgba(0, 0, 0, 0.25) 0px 3px 20px"},attrs:{"interval":4000,"height":"800px","indicator-position":"outside"}},[_c('el-carousel-item',[_c('img',{attrs:{"src":require("../assets/images/carousel1.png"),"alt":""}})]),_c('el-carousel-item',[_c('img',{attrs:{"src":require("../assets/images/carousel2.png"),"alt":""}})]),_c('el-carousel-item',[_c('img',{attrs:{"src":require("../assets/images/carousel3.png"),"alt":""}})]),_c('el-carousel-item',[_c('img',{attrs:{"src":require("../assets/images/carousel4.png"),"alt":""}})]),_c('el-carousel-item',[_c('img',{attrs:{"src":require("../assets/images/carousel5.png"),"alt":""}})])],1)],1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-wrapper"},[_c('div',{staticClass:"header-container"},[_c('a',{attrs:{"href":""}},[_c('h1',{staticClass:"title"},[_vm._v("小果帮芒")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-banner-page"},[_c('div',{staticClass:"sharp-swipe"},[_c('div',{staticClass:"top-banner"},[_c('div',{staticClass:"flex-r-sb company-introduce"},[_c('div',{staticStyle:{"padding":"10px"}},[_c('h1',{staticClass:"company"},[_vm._v("义乌市小果帮芒科技有限公司")]),_c('p',{staticClass:"company-tips"},[_vm._v("       “小果帮芒”是一款专为小微企业量身打造的全面人力资源管理软件，旨在有效提升企业的人才招聘、管理、培训与留任能力，为企业可持续发展提供有力保障。该软件利用科学规范的用工风险管理手段，为企业提供全面、细致的人力资源管理服务。 ")]),_c('p',{staticClass:"company-tips"},[_vm._v("       “小果帮芒”提供全面的人才管理服务，协助企业降低用工风险，优化人才流动和发展，从而实现企业与员工共赢。此外，还支持多维度数据分析和报表生成，帮助企业快速发现和解决人力资源管理中的问题，提高管理效率和决策精度，有效减少了人力资源管理的时间和成本。 ")]),_c('br'),_c('br'),_c('br')]),_c('img',{staticClass:"img",staticStyle:{"width":"200px","height":"200px"},attrs:{"src":require("../assets/images/logo.png"),"alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom-banner w1440"},[_c('div',{staticClass:"bottom-banner-item"},[_c('h2',[_vm._v("人事看板")]),_c('p',[_vm._v("人员列表让数据更加清晰")]),_c('div',{staticClass:"show-img"},[_c('img',{staticClass:"img",staticStyle:{"box-shadow":"rgba(0, 0, 0, 0.25) 0px 3px 20px"},attrs:{"src":require("../assets/images/bg1.png"),"alt":""}})]),_c('div',{staticClass:"bottom-icon-left"},[_c('img',{attrs:{"src":require("../assets/images/bottom-icon2.png"),"alt":""}})]),_c('div',{staticClass:"bottom-icon-right"},[_c('img',{attrs:{"src":require("../assets/images/bottom-icon1.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"#f5f5f5"}},[_c('div',{staticClass:"bottom-banner w1440"},[_c('div',{staticClass:"bottom-banner-item"},[_c('h2',[_vm._v("合同管理")]),_c('p',[_vm._v("电子合同签署与管理")]),_c('div',{staticClass:"show-img"},[_c('img',{staticClass:"img",attrs:{"src":require("../assets/images/carousel4.png"),"alt":""}})]),_c('div',{staticClass:"bottom-icon-left1"},[_c('img',{attrs:{"src":require("../assets/images/bottom-icon4.png"),"alt":""}}),_c('img',{staticStyle:{"position":"absolute","top":"98px","width":"524px","left":"159px"},attrs:{"src":require("../assets/images/htgl.png"),"alt":""}})]),_c('div',{staticClass:"bottom-icon-right1",staticStyle:{"right":"136px","bottom":"-108px"}},[_c('img',{staticStyle:{"width":"227px","box-shadow":"rgba(0, 0, 0, 0.25) 0px 3px 20px"},attrs:{"src":require("../assets/images/ldht.png"),"alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom-banner w1440"},[_c('div',{staticClass:"bottom-banner-item"},[_c('h2',[_vm._v("通知公告")]),_c('p',[_vm._v("通过后台发送公告给员工")]),_c('div',{staticClass:"show-img"},[_c('img',{staticClass:"img",staticStyle:{"box-shadow":"rgba(0, 0, 0, 0.25) 0px 3px 20px"},attrs:{"src":require("../assets/images/tzgg.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer-wrapper"},[_c('div',{staticClass:"copyright"},[_c('div',{staticClass:"copyright-inner"},[_c('span',[_vm._v("Copyright © 2023-NOW mgool.cc 备案号："),_c('a',{attrs:{"href":"https://beian.miit.gov.cn","target":"_blank"}},[_vm._v("浙ICP备2022034460号-1")]),_vm._v(" 义乌市小果帮芒科技有限公司 版权所有")]),_c('span')])])])
}]

export { render, staticRenderFns }