<template>
  <div class="container">
    <div class="header-wrapper">
      <div class="header-container">
        <a href="">
          <h1 class="title">小果帮芒</h1>
        </a>
      </div>
    </div>
    <div class="home">
      <div class="top-banner-page">
        <div class="sharp-swipe">
          <div class="top-banner">
            <div class="flex-r-sb company-introduce">
              <div style="padding: 10px">
                <h1 class="company">义乌市小果帮芒科技有限公司</h1>
                <p class="company-tips">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“小果帮芒”是一款专为小微企业量身打造的全面人力资源管理软件，旨在有效提升企业的人才招聘、管理、培训与留任能力，为企业可持续发展提供有力保障。该软件利用科学规范的用工风险管理手段，为企业提供全面、细致的人力资源管理服务。
                </p>
                <p class="company-tips">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“小果帮芒”提供全面的人才管理服务，协助企业降低用工风险，优化人才流动和发展，从而实现企业与员工共赢。此外，还支持多维度数据分析和报表生成，帮助企业快速发现和解决人力资源管理中的问题，提高管理效率和决策精度，有效减少了人力资源管理的时间和成本。
                </p>
                <br />
                <br />
                <br />
                <!-- <el-button type="primary" @click="jumpToSystem">
                  立即使用
                </el-button> -->
              </div>
              <img
                style="width: 200px; height: 200px"
                class="img"
                src="../assets/images/logo.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="products">
        <el-carousel
          :interval="4000"
          height="800px"
          indicator-position="outside"
          style="
            max-width: 1440px;
            margin: 0 auto;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 20px;
          "
        >
          <el-carousel-item>
            <img src="../assets/images/carousel1.png" alt="" />
          </el-carousel-item>
          <el-carousel-item>
            <img src="../assets/images/carousel2.png" alt="" />
          </el-carousel-item>
          <el-carousel-item>
            <img src="../assets/images/carousel3.png" alt="" />
          </el-carousel-item>
          <el-carousel-item>
            <img src="../assets/images/carousel4.png" alt="" />
          </el-carousel-item>
          <el-carousel-item>
            <img src="../assets/images/carousel5.png" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="bottom-banner w1440">
        <div class="bottom-banner-item">
          <h2>人事看板</h2>
          <p>人员列表让数据更加清晰</p>
          <div class="show-img">
            <img
              class="img"
              style="box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 20px"
              src="../assets/images/bg1.png"
              alt=""
            />
          </div>
          <div class="bottom-icon-left">
            <img src="../assets/images/bottom-icon2.png" alt="" />
          </div>
          <div class="bottom-icon-right">
            <img src="../assets/images/bottom-icon1.png" alt="" />
          </div>
        </div>
      </div>
      <div style="background-color: #f5f5f5">
        <div class="bottom-banner w1440">
          <div class="bottom-banner-item">
            <h2>合同管理</h2>
            <p>电子合同签署与管理</p>
            <div class="show-img">
              <img class="img" src="../assets/images/carousel4.png" alt="" />
            </div>
            <div class="bottom-icon-left1">
              <img src="../assets/images/bottom-icon4.png" alt="" />
              <img
                src="../assets/images/htgl.png"
                alt=""
                style="position: absolute; top: 98px; width: 524px; left: 159px"
              />
            </div>
            <div
              class="bottom-icon-right1"
              style="right: 136px; bottom: -108px"
            >
              <img
                src="../assets/images/ldht.png"
                alt=""
                style="
                  width: 227px;
                  box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 20px;
                "
              />
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-banner w1440">
        <div class="bottom-banner-item">
          <h2>通知公告</h2>
          <p>通过后台发送公告给员工</p>
          <div class="show-img">
            <img
              style="box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 20px"
              class="img"
              src="../assets/images/tzgg.png"
              alt=""
            />
          </div>
        </div>
      </div>

      <!--底部-->
      <footer class="footer-wrapper">
        <div class="copyright">
          <div class="copyright-inner">
            <span
              >Copyright © 2023-NOW mgool.cc 备案号：<a
                href="https://beian.miit.gov.cn"
                target="_blank"
                >浙ICP备2022034460号-1</a
              >
              义乌市小果帮芒科技有限公司 版权所有</span
            >
            <span></span>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped lang="scss">
::v-deep .el-menu.el-menu--horizontal {
  border: none;
}
::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
  color: #333333;
}
::v-deep .el-menu-item {
  width: 120px !important;
  height: 120px !important;
  text-align: center;
}
</style>
