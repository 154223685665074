import Vue from 'vue'
import App from './App.vue'
import Element from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
// import {Notification} from 'element-ui'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import "./assets/styles/all.scss"
import "./assets/styles/global.scss"

Vue.use(ElementUI, {
  size:  'small'
})

Vue.use(Element, { locale })
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
